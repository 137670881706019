<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="12">
            <h6 class="filter-heading d-none d-lg-block">Filtros</h6>
          </b-col>
        </b-row>
        <!-- Filters' Card -->
        <b-card>
          <!-- Price Slider -->
          <!-- <div class="price-slider">
            <h6 class="filter-title mt-0">
              Rango de precio
            </h6>
            <vue-slider
              v-model="filters.priceRange"
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </div> -->

          <div class="ratings mb-3" v-if="hasNutriScore">
            <h6 class="filter-title mt-0">Score nutrimental</h6>
            <div
              v-for="score in nutriScore"
              :key="score"
              @click="handleNutriScore(score)"
              class="d-flex justify-content-between w-100 mb-1"
            >
              <nutri-score :nutritionalInfo="score" />
              <i
                v-if="filters.nutriScore.includes(score)"
                class="fas fa-circle"
              />
            </div>
          </div>

          <!-- Categories -->
          <div class="product-categories">
            <h6 class="filter-title mt-0">Categorías</h6>
            <b-form-checkbox-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="categories"
              text-field="name"
              value-field="id"
            />
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import NutriScore from "@/@core/components/NutriScore.vue"

export default {
  components: {
    NutriScore,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nutriScore: ["a", "b", "c", "d", "e"],
    }
  },
  methods: {
    handleNutriScore(score) {
      if (!this.filters.nutriScore.includes(score)) {
        this.filters.nutriScore.push(score)
      } else {
        this.filters.nutriScore.splice(
          this.filters.nutriScore.indexOf(score),
          1
        )
      }
    },
  },
  computed: {
    hasNutriScore() {
      return this.products.some(
        (product) => product.product_attributes.nutritional_info
      )
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
