<script>
import { capitalize, getDescriptionArray } from "@core/utils/text-utils"

import NutriScore from "../NutriScore.vue"

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    NutriScore,
  },
  methods: {
    capitalize,
    getDescriptionArray,
  },
}
</script>

<template>
  <b-modal
    id="product-details-modal"
    title="Descripción del producto"
    class="modal-content"
    no-stacking
    no-close-on-esc
    ok-only
    ok-variant="success"
    ok-title="Cerrar"
    @ok="$bvModal.hide(`product-description-modal`)"
  >
    <div class="position-relative mx-4" v-if="product">
      <b-row class="my-2">
        <!-- Left: Product Image Container -->
        <b-col
          cols="12"
          md="5"
          lg="4"
          class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-center">
            <b-img
              :src="`${product.logo}`"
              :alt="`Image of ${product.name}`"
              class="product-img"
              rounded
              fluid
            />
          </div>
        </b-col>

        <!-- Right: Product Details -->
        <b-col cols="12" md="7" lg="8">
          <!-- Product Name -->
          <h4>{{ product.name }}</h4>

          <!-- Product Brand -->
          <b-card-text class="item-company mb-0">
            <p class="mb-0">
              {{ product.variant }}
            </p>
          </b-card-text>

          <!-- Price And Ratings -->
          <div v-if="product.is_edible" class="d-flex align-items-center my-1">
            <div class="ecommerce-details-price d-flex flex-wrap">
              <nutri-score
                v-if="product.nutritional_info && product.is_edible"
                :nutritional-info="product.nutritional_info"
              />
            </div>
          </div>

          <!-- Avability -->
          <b-card-text
            >Producto -
            <span
              :class="product.active_status ? 'text-success' : 'text-danger'"
              >{{ product.active_status ? "Activo" : "Desactivado" }}</span
            ></b-card-text
          >

          <!-- Colors -->
          <div class="product-color-option pt-0">
            <h6>Categorias</h6>
            <ul class="list-unstyled m-0">
              <li
                v-for="category in product.categories_names"
                :key="`category-${category}`"
                class="d-inline-block"
              >
                <b-badge>{{ category }}</b-badge>
              </li>
            </ul>
          </div>

          <hr />

          <div class="d-flex flex-column flex-sm-row">
            <div class="d-flex align-items-center">
              <i class="fas fa-barcode fa-2x mr-1" />
              {{ product.sku }}
            </div>
          </div>

          <hr />
          <ul
            v-if="this.product.store_products_attributes[0].description"
            class="mt-2"
            style="text-align: left"
          >
            <li
              class="mb-50"
              style="list-style: inside"
              v-for="(item, index) in getDescriptionArray(
                this.product.store_products_attributes[0].description
              )"
              :key="index"
            >
              {{ capitalize(item) }}
            </li>
          </ul>
          <hr />
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
